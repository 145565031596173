import {useNavigate} from "react-router";
import '../../../components/TabsHeader/style.scss'
import {useTranslation} from "react-i18next";
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import './styles.scss';
import React, {useEffect, useState} from "react";
import {ReactComponent as ArrowIcon} from "assets/common/arrow.svg";
import {
    CANCEL_PAYMENT_API,
    GET_SUBSCRIPTION_PREMIUM,
    SUBSCRIPTION_PERIOD_API
} from "../../../components/onboarding/PayInfoContainer/constants";
import {getAuth} from "firebase/auth";
import {SUBSCRIPTION_CANCELLED_ROUTE} from "../../../constants/routes.constants";

const Subscription = () => {
    const navigate = useNavigate()
    const [date, setDate] = useState('')
    const [isCancelled, setIsCancelled] = useState(false)
    const { t } = useTranslation('app');
    const user = getAuth();
    const [isPremium, setIsPremium] = useState(null)

    const checkIfSubscription = async () => {
        if(!user?.currentUser?.accessToken) return
        const res = await fetch(GET_SUBSCRIPTION_PREMIUM, {method: "GET",  credentials: 'include', headers: {Authorization: 'Bearer ' + user?.currentUser?.accessToken, "Access-Control-Allow-Origin": "*"}})
            .then((response) => response.json())
        setIsPremium(res)
    }

    const onSend=async()=>{
     await fetch(CANCEL_PAYMENT_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + user?.currentUser?.accessToken,
                "Content-Type": "application/json"
            }, body: null}).then(() => {
            navigate(SUBSCRIPTION_CANCELLED_ROUTE)
        })
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'unsubscribe',
        });
    }

    const getSubscription = async() => {
        const res = await fetch(SUBSCRIPTION_PERIOD_API, {method: "GET", headers: {
                Authorization: 'Bearer ' + user?.currentUser?.accessToken,
                "Content-Type": "application/json"
            }, body: null}).then((res) => {
            return res.json()
        })
        const resultedDate = new Date(res.endDate);
        setIsCancelled(res.isCancelled)
        const day = String(resultedDate.getDate()).padStart(2, '0'); // Ensure two digits
        const month = String(resultedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = resultedDate.getFullYear();

        const formattedDate = `${day}.${month}.${year}`;
        setDate(formattedDate)
    }

    useEffect(() => {
        if(user?.currentUser?.accessToken){
            getSubscription()
            checkIfSubscription()
        }
    }, [user])

    return (
        <div  className="languages-wrapper subscription success-page">
            <div className="tabs-header-container row-container space-between align-center">
                <ArrowIcon className="tabs-header-container__back" onClick={() => navigate(-1)} />
                <div className="tabs-header-container__title typography-14 typography-bold">
                    {t('subscription')}
                </div>
                <div/>
            </div>
            <div className="languages-wrapper__content">
            <div className="success-container column align-center">
                <div className="success-container__title typography-20 typography-500">
                    {t("SubscriptionStatus")}
                </div>
                <div className="success-container__active-since typography-14 mt-16">
                    {t("activeSince")}
                    <span className="success-container__active-since__date">{date}</span>
                </div>
                {isPremium && <div className="red-color typography-14 mt-16">
                    {t("premium_is_active")}
                </div>}
                {isCancelled && <div className="typography-14 mt-16">
                    {t("subscription_was_cancelled")}
                </div>}
            </div>
                {!isCancelled &&
                    <Button
                        className="btn-change-password"
                        onClick={onSend}
                        text={t("cancelSubscriptionBtn")}
                        buttonTheme={ButtonThemes.DARK} width={100}/>
                }
            </div>
        </div>
    )
}

export default Subscription