import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import Modal from 'react-modal';
import {ReactComponent as CloseIcon} from "assets/common/cross.svg";
import {useTranslation} from "react-i18next";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";
import Select from 'react-select';
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";
import {ButtonThemes} from "../../../constants/button.constants";
import {ThemeContext} from "../../../context/themeContext";
import {isEmailValid, isEmptyInput} from "../../../utils/login.utils";
import {FormErrorMessages} from "../../../constants/logInErrorMessages.constants";
import {TFunction} from "i18next";
import {
    PAYMENT_PREMIUM_API,
    URL_CITY,
    URL_COUNTRIES,
    URL_STATE
} from "../../../components/onboarding/PayInfoContainer/constants";
import { Input } from 'components/lego/Input/Input';
import CardInfo from 'components/onboarding/PayInfoContainer/CardInfo';
import {PayCardsContainer} from "../../../components/onboarding/PayInfoContainer/PayCardsContainer/PayCardsContainer";
import { Button } from 'components/lego/Button/Button';
import {SUBSCRIPTION_FAILED_ROUTE, SUBSCRIPTION_SUCCESS_ROUTE} from "../../../constants/routes.constants";
import {useNavigate} from "react-router";
import Loader from "../../../components/Loader/Loader";
import {pricePeriods, priceValues} from "../../../constants/price";


export type TProps = {
    open: boolean;
    setOpen: (val: boolean) => void;
    t: TFunction;
}

export type TSelectValue = {
    value: string;
    label: string
}

const FIRST_NAME_ID ='first_name';
const LAST_NAME_ID ='last_name';
const ZIP_ID ='zp';
const EMAIL_ID='email'
export const Payment = ({open, setOpen}: TProps) => {
    const { theme } = useContext(ThemeContext);
    const navigate=useNavigate()
    const {t} = useTranslation('app')
    const onboarding= useTranslation('onboarding')
    const [isCardNumberForm, setIsCardNumberForm] = useState(false)
    const [first_name, setFirstName]=useState('');
    const [last_name, setLastName]=useState('');
    const [countryList, setCountryList]=useState([]);
    const [stateList, setStateList]=useState([]);
    const [cityList, setCityList]=useState([]);
    const [email,setEmail]=useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [ip, setIp] = useState('');
    const [selectedCountry, setSelectedCountry] = useState<{value: string; label: string; tax: string} | null>(null)
    const [selectedState, setSelectedState] = useState<TSelectValue | null>(null)
    const [selectedCity, setSelectedCity] = useState<TSelectValue | null>(null)
    const [cardNumber, setCardNumber] = useState('');
    const [cvv, setCVV] = useState('');
    const [expDate, setExpDate] = useState('');
    const [cardName, setCardName] = useState('');
    const [zip, setZip]=useState('');
    const {errorState, setErrorState, clearErrorMessage}=useLogInErrorLogic()
    useEffect(() => {
        if(open){
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [open])

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json', {method:"GET"}).then((response) => {
                    return response.json()
                })
                if(response?.ip) setIp(response.ip);
            }
            catch (error) {
                console.error("Failed to fetch IP address:", error);
            }
        }
        fetchIp();
    }, []);

    const clearErrorData=(value:string, setInputValue: (inputValue:string)=>void)=>{
        clearErrorMessage()
        setInputValue(value)
    }

    const getCountryList = useCallback(async () => {
        const res = await fetch(URL_COUNTRIES, {method: "GET"})
            .then((response) => response.json())
        if(!!res?.length) setCountryList(res?.map((i:{name: string, id: number, tax: number}) => ({label: i?.name, value: i?.id, tax: i.tax})))
    },[])

    const getStateList = useCallback(async () => {
        if(!selectedCountry) return
        const res = await fetch(URL_STATE(selectedCountry.value), {method: "GET"}).then((response) => response.json())
        if(!!res?.length)setStateList(res.map((i:{name: string, id: string}) => ({label: i.name, value: i.id})))
    },[selectedCountry])
    const getCityList = useCallback(async () => {
        if( !selectedState) return
        const res = await fetch(URL_CITY(selectedState?.value), {method: "GET"}).then((response) => response.json())
        setCityList(res.map((i:{name: string, id: string}) => ({label: i.name, value: i.id})))
    },[selectedState, selectedCountry])

    useEffect(() => {
        getCountryList()
    }, [])
    useEffect(() => {
        if(selectedCountry?.value)
            getStateList()
    }, [selectedCountry])
    useEffect(() => {
        if(selectedState?.value)
            getCityList()
    }, [selectedState, selectedCountry])

    const onSubmit  = async() => {
        if(!isEmptyInput(first_name)) {
            setErrorState({message:onboarding.t(FormErrorMessages.EMPTY_USERNAME),errorInputId:FIRST_NAME_ID})
            return;
        }

        if(!isEmptyInput(last_name)) {
            setErrorState({message:onboarding.t(FormErrorMessages.EMPTY_USERNAME),errorInputId:LAST_NAME_ID})
            return;
        }
        if(!isEmptyInput(email)) {
            setErrorState({message:onboarding.t(FormErrorMessages.EMPTY_EMAIL),errorInputId:EMAIL_ID})
            return;
        }
        if(!selectedCountry?.value) {
            return;
        }
        if(!selectedState?.value) {
            return;
        }

        if(!isEmailValid(email)) {
            setErrorState({message:onboarding.t(FormErrorMessages.NOT_VALID_EMAIL),errorInputId:EMAIL_ID})
            return;
        }
        if(!isEmptyInput(zip)) {
            return;
        }
        if(!isCardNumberForm){
            return  setIsCardNumberForm(true)
        }
        if(!cardNumber || !cvv || !expDate || !cardName) {
            return
        }
        setIsLoading(true)
        const data = {
            "firstName": first_name,
            "lastName": last_name,
            "country": selectedCountry?.label,
            "state": selectedState?.label,
            "city": selectedCity?.label || 'n/a',
            "currency": "USD",
            "zipCode": zip,
            "address": selectedCountry?.label,
            "tax": selectedCountry?.tax,
            "customerBrowserUserAgent":window.navigator.userAgent,
            "customerIP":ip,
            "card_name":cardNumber,
            "expiry_date":expDate,
            "secret":cvv,
            "card":cardNumber?.replaceAll(' ', ''),
        }
        const token = localStorage.getItem("accessToken")
        let status = true
        const res = await fetch(PAYMENT_PREMIUM_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)}).then((response) => {
            setIsLoading(false)
            if(!response?.ok){
                status = response.ok
                navigate(SUBSCRIPTION_FAILED_ROUTE)
            }
            const contentLength = response.headers.get('Content-Length');
            if (contentLength === '0' || !contentLength) {
                return null;
            }

            return response.json();
        })
        setIsLoading(false)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'premium_monthly_plan',
            event_value: `${currentPrice.toFixed(2)}`,
            event_label: `premium_monthly_plan`

        });
        if(!res?.redirect_url && status)  navigate(SUBSCRIPTION_SUCCESS_ROUTE)
        if(!!res?.redirect_url) window.open(res?.redirect_url, '_self')
    }

    const currentPrice = 24.99

    const wrongCard = useMemo(() => {
        if(cardNumber?.length <4) return false
        const firstDigit = cardNumber[0];
        const firstTwoDigits = cardNumber.slice(0, 2);
        const firstFourDigits = cardNumber.slice(0, 4);
        if (firstDigit === "4") return false ;
        if (firstTwoDigits >= "51" && firstTwoDigits <= "55") return false;
        if (firstFourDigits >= "2221" && firstFourDigits <= "2720") return false;
        return true

    }, [cardNumber])

    return(
        <>
            <Modal
                isOpen={open}
                onRequestClose={() => setOpen(false)}
                contentLabel="Example Modal"
                preventScroll={true}
                className={"payment-modal" + ' ' + theme}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
                style={{"overlay": {backgroundColor: theme === 'dark' ? '#171717' : '#ffffff'}}}
            >
                {isLoading && <Loader/>}
                <div className="payment-modal-content">
                    <div className="payment-modal-content__header-icon">
                        <CloseIcon onClick={() => {
                            setIsCardNumberForm(false)
                            setOpen(false)
                        }}/>
                    </div>
                    <div className="payment-modal-content__header-title">
                        <div className="typography-28">
                            {t("payment_details")}
                        </div>
                    </div>
                    <div className={'paymentForm'}>
                        {!isCardNumberForm ? <>
                            <Input onChange={(value) => clearErrorData(value, setFirstName)}
                                   id={FIRST_NAME_ID}
                                   inputType={'first_name'}
                                   placeholderText={t("first_name")}
                                   errorMessage={errorState.errorInputId === FIRST_NAME_ID ? errorState.message : ''}
                            />
                            <Input onChange={(value) => clearErrorData(value, setLastName)}
                                   id={LAST_NAME_ID}
                                   inputType={'last_name'}
                                   placeholderText={t("last_name")}
                                   errorMessage={errorState.errorInputId === LAST_NAME_ID ? errorState.message : ''}
                            />
                            <Input onChange={(value) => clearErrorData(value, setEmail)}
                                   id={EMAIL_ID}
                                   inputType={'email'}
                                   placeholderText={onboarding.t("createAccountScreen.email.placeholder")}
                                   errorMessage={errorState.errorInputId === EMAIL_ID ? errorState.message : ''}
                            />
                            <Select
                                onChange={setSelectedCountry}
                                options={countryList}
                                value={selectedCountry}
                                className={'basic-select'}
                                placeholder={"Country"}
                                classNamePrefix="select"
                                autoFocus={false}
                                backspaceRemovesValue={false}
                            />
                            <div className="two-in-the-row">
                                <Select
                                    onChange={setSelectedState}
                                    options={stateList}
                                    placeholder={"State"}
                                    value={selectedState}
                                    className={'basic-select'}
                                    classNamePrefix="select"
                                    autoFocus={false}
                                    backspaceRemovesValue={false}
                                />
                                <Select
                                    onChange={setSelectedCity}
                                    options={cityList}
                                    value={selectedCity}
                                    placeholder={"City"}
                                    className={'basic-select'}
                                    classNamePrefix="select"
                                    autoFocus={false}
                                    backspaceRemovesValue={false}
                                />
                                <Input onChange={(value) => clearErrorData(value, setZip)}
                                       id={ZIP_ID}
                                       inputType={'zip'}
                                       placeholderText={"Zip"}
                                       errorMessage={errorState.errorInputId === ZIP_ID ? errorState.message : ''}
                                />
                            </div>
                        </> : <CardInfo
                            cardNumber={cardNumber}
                            wrongCard={wrongCard}
                            expDate={expDate}
                            setExpDate={setExpDate}
                            setCardNumber={setCardNumber}
                            cvv={cvv}
                            setCVV={setCVV}
                            cardName={cardName}
                            setCardName={setCardName}
                        />}
                        <div className="payment-modal-content__footer-icon">
                            <PayCardsContainer/>
                        </div>
                        <div className="payment-modal-content__footer-weekly column">
                            <div className="row-container space-between align-center">
                                <div
                                    className="secondary-payment-text typography-16">{t("premium_plan")}</div>
                                <div className="total-price-wrapper typography-16"> ${currentPrice.toFixed(2)}</div>

                            </div>
                        </div>
                        <div className="payment-modal-content__total">
                            <div className="payment-modal-content__total__name typography-32">{t("totalTitle")}</div>
                            <div className="column align-end">
                                <div
                                    className="payment-modal-content__total__price typography-24 typography-bold">${(currentPrice + (!!selectedCountry?.tax ? +selectedCountry.tax * currentPrice : 0)).toFixed(2)}</div>
                                <div
                                    className="payment-modal-content__total__save typography-14 mt-12">{currentPrice.toFixed(2)} +
                                    ${((!!selectedCountry?.tax ? +selectedCountry?.tax : 0) * currentPrice).toFixed(2)} VAT
                                </div>
                            </div>
                        </div>


                        <Button className="mt-32" onClick={onSubmit}
                                isDisabled={(isCardNumberForm && wrongCard) || isLoading}
                                text={onboarding.t("testBeginningScreen.continueButton")}
                                buttonTheme={ButtonThemes.DARK} width={100}
                        />
                        <div className="payment-modal-content__footer-text mt-24">
                            {/*<div>{t("paymentFirstText")}</div>*/}
                            <div>{onboarding.t("payInfoScreen.footerText")}</div>
                            <div className="row-container justify-center text-align-center">G4Capital OÜ Harju maakond,
                            </div>
                            <div className="row-container justify-center text-align-center">Tallinn, Kesklinna linnaosa,
                                Narva mnt 13, 10151
                            </div>
                            <div>{`${onboarding.t("payInfoScreen.moneyBack.learnMoreText")} `}
                                &nbsp; <a
                                    href={PolicyAnchors.SUBSCRIPTION}>{onboarding.t("payInfoScreen.subscriptionTermsAnchor")}</a>
                            </div>
                        </div>

                    </div>
                </div>

            </Modal>
        </>
    )
}
