import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './RelationshipIssuesScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {MIXPANEL_API} from "../../../components/onboarding/PayInfoContainer/constants";

const answers = [
    "We have different values and outlooks on life. No common interests.",
    "Lack of understanding with my partner. We can't have an open and calm dialogue.",
    "Domestic issues in the relationship. We can't cope with them.",
    "Boredom. Sex has become bland and uninteresting, my partner doesn't want to try anything new, and I haven't been on a date in a long time.",
    "I see that he's more interested in other girls than in me."
]

export const RelationshipIssuesScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    const relationshipIssuesOptions:string[]= [
        t("relationshipIssuesScreen.noCommonInterestsIssueText"),
        t("relationshipIssuesScreen.understandingIssueText"),
        t("relationshipIssuesScreen.domesticIssueText"),
        t("relationshipIssuesScreen.boredomIssueText"),
        t("relationshipIssuesScreen.interestIssueText"),];

    const trackMixpanel = async(option: number) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const subid = localStorage.getItem("subid")
        const email = localStorage.getItem("email")
        const clientId = localStorage.getItem("clientId")
        const data = {
            event: 'onboarding_question_responded',
            data: {
                onboarding_step_number: 4,
                question: 'What relationship issue is currently bothering you the most?',
                respond: answers[option],
            },
            fbclid: fbclid,
            subid: subid,
            clientId: clientId,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }


    return (
        <>
            <ContentContainer>
                <h6 className={'relationshipIssueQuestion'}>{t("relationshipIssuesScreen.question")}</h6>
            </ContentContainer>
            <div>
                {relationshipIssuesOptions.map((optionText, idx)=>{
                  return <NextRouteButton key={optionText} option={optionText}
                                     route={nextRoute}
                                          onClick={() => trackMixpanel(idx)}
                                     buttonTheme={ButtonThemes.DARK}
                                     className={'relationshipIssueOptionButton'} />
                })}
            </div>
        </>
    )
}
