import React, {useContext, useMemo, useState} from "react";
import {Timer} from "../../../components/Timer/Timer";
import SpecialOrderOption from "./SpecialOrderOption";
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import {PayCardsContainer} from "../../../components/onboarding/PayInfoContainer/PayCardsContainer/PayCardsContainer";
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";
import {TFunction} from "i18next";
import {ReactComponent as GiftSvg} from '../../../assets/gift.svg';
import {ThemeContext} from "../../../context/themeContext";
import {ReactComponent as CloseIcon} from '../../../assets/common/cross.svg';
import Modal from 'react-modal';
import {pricePeriods, priceValues, priceWithDiscount, priceWithDiscount60} from "../../../constants/price";
import {EMAIL_VALUE, PHONE_VALUE} from "../../../constants/info";

type TProps = {
    t: TFunction;
    timerDuration:number;
    onClick: () => void;
    setPrice: (data:number) => void;
    setOpen: (data:boolean) => void;
    onSubmit: () => void;
    isTimerOver: boolean;
    isOpen: boolean;
}
const NewDiscountModal = ({t, timerDuration, onClick, setPrice, isTimerOver, setOpen ,isOpen, onSubmit}: TProps) => {
    const [selected, setSelected] = useState(2)
    const { theme } = useContext(ThemeContext);
    const priceVal = priceWithDiscount60[selected - 1]
    const price = priceValues[selected - 1]
    const period = pricePeriods[selected - 1]
    const text = useMemo(() =>{
        return t("payInfoScreen.discountDescription").replace("44.99", priceVal).replace("4-", period+'-').replace("4 ", period+'-')
    },[t, priceVal, period])
    const textWithTimer = useMemo(() =>{
        return t("payInfoScreen.specialOfferDiscount").replace("9.99", priceVal).replace("24.99", price).replaceAll("4-", period+'-').replaceAll("4 ", period+'-')
    },[t, priceVal, period])
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setOpen(false)}
            contentLabel="Example Modal"
            preventScroll={true}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            style={{overlay: {
                    background: "rgb(12, 11, 11, 0.8)"}
            }}
            className={"payment-modal" + ' ' + theme}
        >
            <div className="payment-modal-content__header-icon">
                <CloseIcon onClick={() => {
                    setOpen(false)
                }} />
            </div>
        <div className="special-order">
            <div className="column align-center">
                <div className="typography-28 red-color common-color text-align-center">
                    {t("payInfoScreen.startYourJourney")}
                </div>

            </div>
            <div className="row-container align-center justify-center mt-24 gift-svg">
                <GiftSvg />
            </div>
            <div className="common-color column align-center typography-20 typography-bold mt-24"  id="special-offer-page">{t("payInfoScreen.discountSale50")?.replace('50%', '60%')}</div>
            <div className="special-order__timer row-container align-center justify-content-center mt-24">
                <div className="special-order__timer__container row-container align-center">
                    <Timer duration={timerDuration} className={'headerTimerDesign'} withoutHours/>
                    <div className="red-color typography-16 typography-bold">min</div>
                </div>
            </div>

            <SpecialOrderOption
                plan={1}
                t={t}
                oldPrice={!isTimerOver ? '24.99' : ''}
                newPrice={isTimerOver ? 24.99 : 9.99}
                perDay={42}
                sale={!isTimerOver ? '3.57' : ''}
                total={!isTimerOver ? 1 : 3}
                selected={selected === 1}
                setSelected={() => {
                    setSelected(1)
                    setPrice( 24.99)
                }}
            />
            <SpecialOrderOption
                plan={4}
                t={t}
                oldPrice={!isTimerOver ? '89.99' : ''}
                newPrice={isTimerOver ? 89.99 : 35.99}
                perDay={28}
                sale={!isTimerOver ? '3.21' : ''}
                total={!isTimerOver ? 1 : 3}
                popular
                selected={selected === 2}
                setSelected={() => {
                    setSelected(2)
                    setPrice( 89.99)
                }}
            />
            <SpecialOrderOption
                plan={12}
                t={t}
                oldPrice={!isTimerOver ? '199.99' : ''}
                newPrice={isTimerOver ? 199.99 : 79.99}
                perDay={95}
                sale={!isTimerOver ? '2.38' : ''}
                total={!isTimerOver ? 0 : 2}
                selected={selected === 3}
                setSelected={() => {
                    setSelected(3)
                    setPrice( 199.99)
                }}
            />

            <Button onClick={onSubmit} text={t("payInfoScreen.getMyPlan")} buttonTheme={ButtonThemes.DARK} width={100} className="mt-24" />
            <div className="row-container align-center justify-center mt-16">
                <PayCardsContainer />
            </div>
            <div className="special-order__footer-text mt-16 column">
                <div className="typography-10 common-color">
                    {isTimerOver ? text : textWithTimer}
                </div>
                <div className="typography-10 common-color">
                    {t("payInfoScreen.discountApp")}
                </div>
                <p className="typography-10 common-color">
                    {t("payInfoScreen.contact_us")} {EMAIL_VALUE}, {PHONE_VALUE}
                </p>
                <p className="typography-10 common-color">{`${t("payInfoScreen.learnMoreAboutTermsText")} `}
                    <a href={PolicyAnchors.SUBSCRIPTION}>{t("payInfoScreen.subscriptionTermsAnchor")}</a>
                </p>
            </div>
        </div>
        </Modal>
    )
}

export default NewDiscountModal